import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppServiceService {

  constructor() { }
public selectedNewsId : string="";
  getnews()
{
     return [
      {
        title:"Prize distribution program - 2024-25",
        Description:"Photos of prize distribution program - 2024-25",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1738216359/hm8otvp3uibddpdsiw5l.jpg',
        Image: ['assets/img/p1-lp-24 (1).jpeg','assets/img/p1-lp-24 (2).jpeg','assets/img/p1-lp-24 (3).jpeg','assets/img/p1-lp-24 (4).jpeg','assets/img/p1-lp-24 (5).jpeg','assets/img/p1-lp-24 (6).jpeg','assets/img/p1-lp-24 (7).jpeg','assets/img/p1-lp-24 (8).jpeg','assets/img/p1-lp-24 (9).jpeg','assets/img/p1-lp-24 (10).jpeg','assets/img/p1-lp-24 (11).jpeg','assets/img/p1-lp-24 (12).jpeg',],
        Date: '22-Jan-2025',
        newsid:'newsid041'
       },
      {
        title:"Lower primary activities for the month of December",
        Description:"Photos of lower primary activities for the month of December",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1738221568/ukptr8kjxvmevwuhudff.jpg',
        Image: ['assets/img/lp-dec-24 (4).jpeg','assets/img/lp-dec-24 (5).jpeg','assets/img/lp-dec-24 (6).jpeg','assets/img/lp-dec-24 (7).jpeg','assets/img/lp-dec-24 (8).jpeg','assets/img/lp-dec-24 (9).jpeg','assets/img/lp-dec-24 (10).jpeg','assets/img/lp-dec-24 (11).jpeg','assets/img/lp-dec-24 (12).jpeg','assets/img/lp-dec-24 (13).jpeg','assets/img/lp-dec-24 (14).jpeg','assets/img/lp-dec-24 (15).jpeg','assets/img/lp-dec-24 (16).jpeg','assets/img/lp-dec-24 (37).jpeg','assets/img/lp-dec-24 (1).jpg','assets/img/lp-dec-24 (2).jpg','assets/img/lp-dec-24 (3).jpg','assets/img/lp-dec-24 (6).jpg','assets/img/lp-dec-24 (21).jpeg','assets/img/lp-dec-24 (5).jpg'],
        Date: '22-Jan-2025',
        newsid:'newsid040'
       },
      {
        title:"Lower primary activities for the month of November",
        Description:"Photos of lower primary activities for the month of November",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1734723987/r1ny3bp8aetd5wdtkubl.jpg',
        Image: ['assets/img/LP-Nov-24-1 (1).jpg','assets/img/LP-Nov-24-1 (2).jpg','assets/img/LP-Nov-24-1 (3).jpg','assets/img/LP-Nov-24-1 (4).jpg','assets/img/LP-Nov-24-1 (5).jpg','assets/img/LP-Nov-24-1 (6).jpg','assets/img/LP-Nov-24 (7).jpeg','assets/img/LP-Nov-24-1 (8).jpeg','assets/img/LP-Nov-24-1 (1).jpeg','assets/img/LP-Nov-24-1 (2).jpeg','assets/img/LP-Nov-24-1 (3).jpeg','assets/img/LP-Nov-24-1 (6).jpeg','assets/img/LP-Nov-24-1 (7).jpeg','assets/img/LP-Nov-24-1 (8).jpeg','assets/img/LP-Nov-24-1 (9).jpeg','assets/img/LP-Nov-24-1 (10).jpeg','assets/img/LP-Nov-24-1 (11).jpeg','assets/img/LP-Nov-24-1 (12).jpeg','assets/img/LP-Nov-24-1 (13).jpeg','assets/img/LP-Nov-24-1 (14).jpeg'],
        Date: '09-Dec-2024',
        newsid:'newsid039'
       },
      {
        title:"Monacting competition by Lower primary",
        Description:"Photos of monacting competition done by lower primary",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1734721619/gnqz99bvarwrr3lgr0gu.jpg',
        Image: ['assets/img/LP-Nov-24 (10).jpeg','assets/img/LP-Nov-24 (11).jpeg','assets/img/LP-Nov-24 (12).jpeg','assets/img/LP-Nov-24 (18).jpeg','assets/img/LP-Nov-24 (1).jpeg','assets/img/LP-Nov-24 (19).jpeg','assets/img/LP-Nov-24 (2).jpeg','assets/img/LP-Nov-24 (4).jpeg','assets/img/LP-Nov-24 (4).jpeg','assets/img/LP-Nov-24 (5).jpeg','assets/img/LP-Nov-24 (6).jpeg','assets/img/LP-Nov-24 (7).jpeg','assets/img/LP-Nov-24 (8).jpeg','assets/img/LP-Nov-24 (9).jpeg','assets/img/LP-Nov-24 (13).jpeg','assets/img/LP-Nov-24 (14).jpeg','assets/img/LP-Nov-24 (15).jpeg','assets/img/LP-Nov-24 (16).jpeg','assets/img/LP-Nov-24 (17).jpeg','assets/img/LP-Nov-24 (3).jpeg'],
        Date: '09-Dec-2024',
        newsid:'newsid038'
       },
      {
        title:"Higher primary activities for the month of November",
        Description:"Photos of higher primary activities for the month of November",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1734274008/qoftxvugvyredgntkjwn.jpg',
        Image: ['assets/img/hp-nov-24 (4).jpg','assets/img/hp-nov-24 (3).jpg','assets/img/hp-nov-24 (1).jpg','assets/img/hp-nov-24 (5).jpg','assets/img/hp-nov-24 (7).jpg','assets/img/hp-nov-24 (6).jpg','assets/img/hp-nov-24 (1).jpg'],
        Date: '09-Dec-2024',
        newsid:'newsid037'
       },
      {
        title:"Lower primary activities for the month of August and September",
        Description:"Photos of lower primary activities for the month of August and September",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1729795385/y1cazngjpfdyrxb8ag5i.jpg',
        Image: ['assets/img/sep-aug-Lp24 (1).jpeg','assets/img/sep-aug-Lp24 (3).jpeg','assets/img/sep-aug-Lp24 (4).jpeg','assets/img/sep-aug-Lp24 (5).jpeg','assets/img/sep-aug-Lp24 (6).jpeg','assets/img/sep-aug-Lp24 (2).jpeg'],
        Date: '22-Oct-2024',
        newsid:'newsid036'
       },
      {
        title:"Market Day by Lower primary",
        Description:"Photos of market day by lower primary",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1729792089/eh4ujkuk7wburw6izko2.jpg',
        Image: ['assets/img/market-day-Lp-actvites (1).jpeg','assets/img/market-day-Lp-actvites (41).jpeg','assets/img/market-day-Lp-actvites (3).jpeg','assets/img/market-day-Lp-actvites (43).jpeg','assets/img/market-day-Lp-actvites (40).jpeg','assets/img/market-day-Lp-actvites (32).jpeg','assets/img/market-day-Lp-actvites (23).jpeg','assets/img/market-day-Lp-actvites (24).jpeg','assets/img/market-day-Lp-actvites (27).jpeg','assets/img/market-day-Lp-actvites (21).jpeg','assets/img/market-day-Lp-actvites (54).jpeg','assets/img/market-day-Lp-actvites (55).jpeg','assets/img/market-day-Lp-actvites (18).jpeg','assets/img/market-day-Lp-actvites (46).jpeg','assets/img/market-day-Lp-actvites (44).jpeg','assets/img/market-day-Lp-actvites (11).jpeg','assets/img/market-day-Lp-actvites (14).jpeg','assets/img/market-day-Lp-actvites (19).jpeg','assets/img/market-day-Lp-actvites (15).jpeg','assets/img/market-day-Lp-actvites (47).jpeg','assets/img/market-day-Lp-actvites (48).jpeg','assets/img/market-day-Lp-actvites (49).jpeg','assets/img/market-day-Lp-actvites (45).jpeg','assets/img/market-day-Lp-actvites (35).jpeg','assets/img/market-day-Lp-actvites (25).jpeg','assets/img/market-day-Lp-actvites (26).jpeg','assets/img/market-day-Lp-actvites (29).jpeg','assets/img/market-day-Lp-actvites (14).jpeg'],
        Date: '22-Oct-2024',
        newsid:'newsid035'
       },
      {
        title:"Higher primary activities for the month of August",
        Description:"Photos of Higher primary activities for the month of August",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1725621749/dn2p3u8aj4a6lzjspb41.jpg',
        Image: ['assets/img/Hp-Sep-24 (1).jpg','assets/img/Hp-Sep-24 (2).jpg','assets/img/Hp-Sep-24 (3).jpg','assets/img/Hp-Sep-24 (4).jpg','assets/img/Hp-Sep-24 (5).jpg','assets/img/Hp-Sep-24 (5).jpg','assets/img/Hp-Sep-24 (6).jpg','assets/img/Hp-Sep-24 (2).jpeg','assets/img/Hp-Sep-24 (3).jpeg','assets/img/Hp-Sep-24 (4).jpeg','assets/img/Hp-Sep-24 (5).jpeg',],
        Date: '05-Sep-2024',
        newsid:'newsid034'
       },
      {
        title:"Lower primary activities for the month of July",
        Description:"Photos of Lower primary activities for the month of July",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1724786175/rekjuwrytiohn579qcks.jpg',
        Image: ['assets/img/LP-Jul-24 (1).jpg','assets/img/LP-Jul-24 (2).jpg','assets/img/LP-Jul-24 (3).jpg','assets/img/LP-Jul-24 (4).jpg','assets/img/LP-Jul-24 (5).jpg','assets/img/LP-Jul-24 (6).jpg','assets/img/LP-Jul-24 (7).jpg','assets/img/LP-Jul-24 (8).jpg','assets/img/LP-Jul-24 (9).jpg','assets/img/LP-Jul-24 (10).jpg','assets/img/LP-Jul-24 (11).jpg','assets/img/LP-Jul-24 (12).jpg','assets/img/LP-Jul-24 (13).jpg','assets/img/LP-Jul-24 (14).jpg','assets/img/LP-Jul-24 (15).jpg','assets/img/LP-Jul-24 (1).jpeg','assets/img/LP-Jul-24 (2).jpeg','assets/img/LP-Jul-24 (3).jpeg','assets/img/LP-Jul-24 (4).jpeg','assets/img/LP-Jul-24 (5).jpeg','assets/img/LP-Jul-24 (6).jpeg','assets/img/LP-Jul-24 (7).jpeg','assets/img/LP-Jul-24 (8).jpeg','assets/img/LP-Jul-24 (9).jpeg'],
        Date: '22-Aug-2024',
        newsid:'newsid033'
       },
      {
        title:"Nursery activities for the month of July",
        Description:"Photos of Nursery activities for the month of July",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1724179001/powhqcvoyhhyricnrfnq.jpg',
        Image: ['assets/img/Nur-jul-24 (1).jpg','assets/img/Nur-jul-24 (2).jpg','assets/img/Nur-jul-24 (3).jpg','assets/img/Nur-jul-24 (4).jpg','assets/img/Nur-jul-24 (5).jpg','assets/img/Nur-jul-24 (6).jpg','assets/img/Nur-jul-24-7 (1).jpg','assets/img/Nur-jul-24-7 (2).jpg','assets/img/Nur-jul-24-7 (3).jpg','assets/img/Nur-jul-24-7 (4).jpg','assets/img/Nur-jul-24-7 (5).jpg','assets/img/Nur-jul-24-7 (6).jpg','assets/img/nur-jul-24-12 (1).jpg','assets/img/nur-jul-24-12 (2).jpg','assets/img/nur-jul-24-12 (3).jpg','assets/img/nur-jul-24-12 (4).jpg','assets/img/nur-jul-24-12 (5).jpg','assets/img/nur-jul-24-12 (6).jpg'],
        Date: '14-Aug-2024',
        newsid:'newsid032'
       },
      {
        title:"Higher primary activities for the month of July",
        Description:"Photos of Higher primary activities for the month of July",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1722939998/kmu2qziwjbik5cfsyila.jpg',
        Image: ['assets/img/Hp-jul-24 (1).jpg','assets/img/Hp-jul-24 (2).jpeg','assets/img/Hp-jul-24 (3).jpeg','assets/img/Hp-jul-24 (1).jpeg','assets/img/Hp-jul-24 (2).jpg','assets/img/Hp-jul-24 (3).jpg'],
        Date: '4-Aug-2024',
        newsid:'newsid031'
       },
      {
        title:"Higher primary activities for the month of June",
        Description:"Photos of Higher primary activities for the month of June",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1722939570/jauwcp1fambadayhzxbe.jpg',
        Image: ['assets/img/Hp-jun-24 (2).jpeg','assets/img/Hp-jun-24 (1).jpeg','assets/img/Hp-jun-24 (3).jpeg','assets/img/Hp-jun-24 (4).jpeg','assets/img/Hp-jun-24 (1).jpg','assets/img/Hp-jun-24 (2).jpg'],
        newsid:'newsid030'
       },
      {
        title:"Lower primary activities for the month of June",
        Description:"Photos of Lower primary activities for the month of June",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1720109436/h6134wwek6yuk7imnghj.jpg',
        Image: ['assets/img/Lp-jun-24 (1).jpg','assets/img/Lp-jun-24 (2).jpg','assets/img/Lp-jun-24 (3).jpg','assets/img/Lp-jun-24 (5).jpg','assets/img/Lp-jun-24 (6).jpg','assets/img/Lp-jun-24 (7).jpg','assets/img/Lp-jun-24 (10).jpg','assets/img/Lp-jun-24 (12).jpg'],
        Date: '4-Jul-2024',
        newsid:'newsid029'
       },
      {
        title:"Nursery activities for the month of June",
        Description:"Photos of Nursery activities for the month of June",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1720010116/r3jtyr3cfanpuugzrib8.jpg',
        Image: ['assets/img/nus-june-24 (1).jpg','assets/img/nus-june-24 (2).jpg','assets/img/nus-june-24 (3).jpg','assets/img/nus-june-24 (4).jpg','assets/img/nus-june-24-1 (3).jpg','assets/img/nus-june-24-1 (4).jpg','assets/img/nus-june-24-1 (5).jpg','assets/img/nus-june-24-(21).jpg','assets/img/nus-june-24-(23).jpg','assets/img/nus-june-24-(25).jpg','assets/img/nus-june-24-(26).jpg'],
        Date: '4-Jul-2024',
        newsid:'newsid028'
       },
      {
        title:"Lower primary activities for the month of March",
        Description:"Photos of Lower primary activities for the month of March",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1714155798/r9nklphhmn2h0nckze1l.jpg',
        Image: ['assets/img/Lp-apr-24 (4).jpg','assets/img/Lp-apr-24 (1).jpg','assets/img/Lp-apr-24 (2).jpg','assets/img/Lp-apr-24 (3).jpg',],
        Date: '4-Apr-2024',
        newsid:'newsid027'
       },
      {
        title:"Saraswati pooja by lower primary",
        Description:"Photos of Saraswati pooja by lower primary",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1714155740/bqekgd8v2e8yudtbe7w4.jpg',
        Image: ['assets/img/LP-Saraswati-pooja-24(12).jpg','assets/img/LP-Saraswati-pooja-24(13).jpg','assets/img/LP-Saraswati-pooja-24(15).jpg','assets/img/LP-Saraswati-pooja-24(17).jpg','assets/img/LP-Saraswati-pooja-24(16).jpg','assets/img/LP-Saraswati-pooja-24(21).jpg','assets/img/LP-Saraswati-pooja-24(25).jpg','assets/img/LP-Saraswati-pooja-24(22).jpg',],
        Date: '4-Apr-2024',
        newsid:'newsid026'
       },
      {
        title:"Olympiad gold medalist - 2023-2024",
        Description:"Photos of Olympiad - 2023-2024",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1711984602/csxtbusbnhev8hwetq6h.jpg',
        Image: ['assets/img/Olympiad-gold-medalist-24.jpg'],
        Date: '14-Mar-2024',
        newsid:'newsid025'
       },
      {
        title:"Saraswathi pooja by higher primary",
        Description:"Photos of Saraswathi pooja by higher primary",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1711983869/yjhqnr1pn5c19njztoc0.jpg',
        Image: ['assets/img/Saraswati-Pooja-24 (1).jpeg','assets/img/Saraswati-Pooja-24 (2).jpeg','assets/img/Saraswati-Pooja-24 (3).jpeg','assets/img/Saraswati-Pooja-24 (4).jpeg','assets/img/Saraswati-Pooja-24 (6).jpeg','assets/img/Saraswati-Pooja-24 (7).jpeg','assets/img/Saraswati-Pooja-24 (8).jpeg'],
        Date: '14-Mar-2024',
        newsid:'newsid024'
       },
      {
        title:"Lower primary activities for the month of February",
        Description:"Photos of Lower primary activities for the month of February",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1710181239/ee8psog9songjy3da1ei.jpg',
        Image: ['assets/img/LP-Jan-24 (1).jpg','assets/img/LP-Jan-24 (2).jpg','assets/img/LP-Jan-24 (3).jpg','assets/img/LP-Jan-24 (4).jpg','assets/img/LP-Jan-24 (5).jpg','assets/img/LP-Jan-24 (6).jpg','assets/img/LP-Jan-24 (7).jpg','assets/img/LP-Jan-24 (8).jpg','assets/img/LP-Jan-24 (10).jpg','assets/img/LP-Jan-24 (11).jpg'],
        Date: '08-Mar-2024',
        newsid:'newsid023'
       },
      {
        title:"Annual day program and activity photos of lower primary",
        Description:"Photos of Annual day program and activity by lower primary",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1708714555/jb0iltwsvxu7skrievhg.jpg',
        Image: ['assets/img/LP-annual-24 (1).jpg','assets/img/LP-annual-24 (2).jpg','assets/img/LP-annual-24 (3).jpg','assets/img/LP-annual-24 (4).jpg','assets/img/LP-annual-24 (5).jpg','assets/img/LP-annual-24 (6).jpg','assets/img/LP-annual-24 (2).jpeg','assets/img/LP-annual-24 (3).jpeg','assets/img/LP-annual-24 (4).jpeg','assets/img/LP-annual-24 (6).jpeg'],
        Date: '14-Feb-2024',
        newsid:'newsid22'
     },
      {
        title:"Republic Day celebration",
        Description:"Photos of Republic Day celebration",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1707328317/z96kvbxv3gof2a1bwkbb.png',
        Image: ['assets/img/Republic-day-photo-2024 (8).png','assets/img/Republic-day-photo-2024 (9).png','assets/img/Republic-day-photo-2024 (10).png','assets/img/Republic-day-photo-2024 (8).jpg','assets/img/Republic-day-photo-2024 (9).jpg','assets/img/Republic-day-photo-2024 (10).jpg','assets/img/Republic-day-photo-2024 (12).png','assets/img/Republic-day-photo-2024 (12).jpg','assets/img/Republic-day-photo-2024 (13).png','assets/img/Republic-day-photo-2024 (16).png','assets/img/Republic-day-photo-2024 (14).png','assets/img/Republic-day-photo-2024 (22).png'],
        Date: '22-Jan-2024',
        newsid:'newsid21'
     },
      {
         title:"Lower primary Sports Day - 2023",
         Description:"Photos of Lower primary Sports Day - 2023",
         ShortDescription: '',
         Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1706718258/chnmt5vuh08d492liwib.jpg',
         Image: ['assets/img/LP-sports-2023 (1).jpg','assets/img/LP-sports-2023 (12).jpg','assets/img/LP-sports-2023 (3).jpg','assets/img/LP-sports-2023 (4).jpg','assets/img/LP-sports-2023 (5).jpg','assets/img/LP-sports-2023 (6).jpg','assets/img/LP-sports-2023 (7).jpg','assets/img/LP-sports-2023 (8).jpg','assets/img/LP-sports-2023 (9).jpg','assets/img/LP-sports-2023 (10).jpg','assets/img/LP-sports-2023 (11).jpg','assets/img/LP-sports-2023 (12).jpg'],
         Date: '02-Jan-2024',
         newsid:'newsid20'
      },
      {
         title:"Nursery activities",
         Description:"Photos of Nursery activities",
         ShortDescription: '',
         Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1704912310/s2dffx018cw5omjyybo1.jpg',
         Image: ['assets/img/Nur-2023 (1).jpg','assets/img/Nur-2023 (2).jpg','assets/img/Nur-2023 (3).jpg','assets/img/Nur-2023 (4).jpg','assets/img/Nur-2023 (5).jpg','assets/img/Nur-2023 (6).jpg','assets/img/Nur-2023 (7).jpg','assets/img/Nur-2023 (1).jpeg','assets/img/Nur-2023 (2).jpeg','assets/img/Nur-2023 (3).jpeg','assets/img/Nur-2023 (4).jpeg','assets/img/Nur-2023(5).jpeg'],
         Date: '22-Jan-2024',
         newsid:'newsid19'
      },
       {
        title:"Dance Competition",
        Description:"Photos of dance competition",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1706554719/zcctea78tybb4ob9roqc.jpg',
        Image: ['assets/img/dance-competition (5).jpeg','assets/img/dance-competition (6).jpeg','assets/img/dance-competition (7).jpeg','assets/img/dance-competition (8).jpeg','assets/img/dance-competition (9).jpeg','assets/img/dance-competition (10).jpeg','assets/img/dance-competition (11).jpeg','assets/img/dance-competition (13).jpeg','assets/img/dance-competition (25).jpeg','assets/img/dance-competition (24).jpeg'],
        Date: '22-Jan-2024',
        newsid:'newsid18'
       }, 
      {
        title:"Ghanav of Std 5 won 2nd prize in Karate competition",
        Description:"",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1706374904/jgnthd44mkobcddfuikl.jpg',
        Image: ['assets/img/Ghanav -5B - Karate COmpetiotin 2nd Prize (3).jpeg'],
        Date: '22-Jan-2024',
        newsid:'newsid17'
       }, 
      {
        title:"Sports Day - 2023-2024",
        Description:"Photos of Sports Day - 2023-2024",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1706034040/mcf4mbug8dgjljv7ji29.png',
        Image: ['assets/img/4. Sports Day.png','assets/img/6. Sports Day.png','assets/img/7. Sports Day.png','assets/img/5. Sports Day (1).png'],
        Date: '22-Jan-2024',
        newsid:'newsid16'
       }, 
      {
        title:"National Youth Day - 2023-2024",
        Description:"Photos of National Youth Day -2023-2024",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1706033210/gwrn8erup1yaul4dcebl.jpg',
        Image: ['assets/img/2. National Youth Day.jpg','assets/img/1.National Youth Day.jpg'],
        Date: '22-Jan-2024',
        newsid:'newsid15'
       }, 
      {
        title:"Asset Exam 2nd Round Winners Photos 2023-2024",
        Description:"Photos of Asset Exam 2nd Round Winners Photos 2023-2024",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1706010353/m4bv6xvnkwhsl06j7bdy.jpg',
        Image: ['assets/img/asset-exam-img (1).jpeg','assets/img/asset-exam-img (3).jpeg'],
        Date: '22-Jan-2024',
        newsid:'newsid14'
       },
      {
        title:"Lower Primary activities for the month of December and Children's Day celebration",
        Description:"Lower Primary activities for the month of December and Children's Day celebration ",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1704904567/gjlh2bxm1xgu013pwp0k.jpg',
        Image: ['assets/img/LP-Dec-23 (1).jpg','assets/img/LP-Dec-23 (2).jpg','assets/img/LP-Dec-23 (3).jpg','assets/img/LP-Dec-23 (4).jpg','assets/img/LP-Dec-23 (6).jpg','assets/img/LP-Dec-23 (11).jpg','assets/img/LP-Dec-23 (7).jpg','assets/img/LP-Dec-23 (9).jpg','assets/img/LP-Dec-23 (10).jpg','assets/img/LP-Dec-23 (12).jpg'],
        Date: '08-Dec-2023',
        newsid:'newsid013'
       },
      {
        title:"Highier Primary activities for the month of December",
        Description:"Highier Primary activities for the month of December",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1703269179/zjuknvrh2n3byohspkxw.jpg',
        Image: ['assets/img/dec-act-23 (1).jpeg','assets/img/dec-act-23 (1).jpg','assets/img/dec-act-23 (2).jpeg','assets/img/dec-act-23 (2).jpg','assets/img/dec-act-23 (3).jpg','assets/img/dec-act-23 (4).jpg','assets/img/dec-act-23 (5).jpg','assets/img/dec-act-23 (7).jpg','assets/img/dec-act-23 (8).jpg','assets/img/dec-act-23 (9).jpg','assets/img/dec-act-23 (10).jpg','assets/img/dec-act-23 (11).jpg','assets/img/dec-act-23 (12).jpg','assets/img/dec-act-23 (13).jpg','assets/img/dec-act-23 (14).jpg'],
        Date: '15-Dec-2023',
        newsid:'newsid12'
       },
      {
        title:"Lower Primary activities for the month of November",
        Description:"Lower Primary activities for the month of November",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1702280920/cpg1o9zdle7tag9e2ui3.jpg',
        Image: ['assets/img/Nov-23-act-photos (1).jpg','assets/img/Nov-23-act-photos (4).jpg','assets/img/Nov-23-act-photos (7).jpg','assets/img/Nov-23-act-photos (16).jpg','assets/img/Nov-23-act-photos (13).jpg','assets/img/Nov-23-act-photos (14).jpg','assets/img/Nov-23-act-photos (9).jpg','assets/img/Nov-23-act-photos (11).jpg'],
        Date: '24-Nov-2023',
        newsid:'newsid11'
       },
      {
        title:"Solo dance competition,craft competition and group activity photos- October 2023",
        Description:"Solo dance competition,craft competition and group activity photos- October 2023",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/d1tyq1zlbvvbvxcabzlm.jpg',
        Image: ['assets/img/group-act-2023-oct (1).jpg','assets/img/group-act-2023-oct (2).jpg','assets/img/group-act-2023-oct (8).jpg','assets/img/group-act-2023-oct (4).jpg','assets/img/group-act-2023-oct (5).jpg','assets/img/group-act-2023-oct (6).jpg'],
        Date: '08-Sep-2023',
        newsid:'newsid10'
       },
      {
        title:"Lower Primary activities for the month of August",
        Description:"Photos of lower primary activities for the month of August",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1695991595/uiosbjwvwf4yj9ua9rtv.jpg',
        Image: ['assets/img/aug-activities-23 (1).jpeg','assets/img/aug-activities-23 (1).jpg','assets/img/aug-activities-23 (2).jpeg','assets/img/aug-activities-23 (2).jpg','assets/img/aug-activities-23 (3).jpeg','assets/img/aug-activities-23 (3).jpg'],
        Date: '08-Sep-2023',
        newsid:'newsid09'
       },
       {
        title:"Lower Primary activities for the month of July",
        Description:"Photos of lower primary activities for the month of July",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1696408454/tji3wcashpfoy4szjfo9.jpg',
        Image: ['assets/img/july-activity-LP-23 (1).jpeg','assets/img/july-activity-LP-23 (1).jpg','assets/img/july-activity-LP-23 (5).jpeg','assets/img/july-activity-LP-23 (11).jpg','assets/img/july-activity-LP-23 (9).jpg','assets/img/july-activity-LP-23 (3).jpg','assets/img/july-activity-LP-23 (15).jpg','assets/img/july-activity-LP-23 (12).jpg'],
        Date: '08-Sep-2023',
        newsid:'newsid08'
       },
      {
        title:"Playhome activities for the month of June",
        Description:"Photos of playhome activities for the month of June",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1690539179/hafwfxyousqs03cltfme.jpg',
        Image: ['assets/img/playhome-acti-23  (1).jpg','assets/img/playhome-acti-23  (2).jpg','assets/img/playhome-acti-23  (4).jpg','assets/img/playhome-acti-23  (5).jpg'],
        Date: '24-Jun-2023',
        newsid:'newsid07'
       },
      {
        title:"Environment Day Celebrations - 2023-24",
        Description:"Photos of Environment Day Celebrations - 2023-24",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1690538955/huijfe6nikzbfhlphtwh.jpg',
        Image: ['assets/img/Environmentday-23 (1).jpeg','assets/img/Environmentday-23 (2).jpeg','assets/img/Environmentday-23 (3).jpeg','assets/img/Environmentday-23 (4).jpeg'],
        Date: '24-Jun-2023',
        newsid:'newsid06'
       },
       {
        title:"Yoga Day - 2023-24",
        Description:"Photos of Yoga Day - 2023-24",
        ShortDescription: '',
        Thumbnail:'http://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1690539429/ru1a3stb1ly6b52bh1wx.jpg',
        Image: ['assets/img/Yoga day-23  (1).jpeg','assets/img/Yoga day-23  (2).jpeg','assets/img/Yoga day-23  (3).jpeg','assets/img/Yoga day-23  (4).jpeg'],
        Date: '24-Jun-2023',
        newsid:'newsid05'
       },
       {
        title:"Investiture Ceremony - 2023-24",
        Description:"Photos of Investiture Ceremony - 2023-24",
        ShortDescription: '',
        Thumbnail:'https://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1690209767/yz74ckuwn9sa0gkqenqz.jpg',
        Image: ['assets/img/Investiture (1).jpeg','assets/img/Investiture (2).jpeg','assets/img/Investiture (3).jpeg','assets/img/Investiture (6).jpeg'],
        Date: '24-Jun-2023',
        newsid:'newsid04'
       }, 
      {
        title:"Lower Primary activities for the month of June 2023",
        Description:"Photos of lower primary activities for the month of June 2023",
        ShortDescription: '',
        Thumbnail:'https://res.cloudinary.com/hs5fb0l8g/image/upload/c_scale,h_75,q_90,w_100/v1690199869/gnlgj191pz9ohaqngz4a.jpg',
        Image: ['assets/img/Lower Primary-jun-23 (1).jpeg','assets/img/Lower Primary-jun-23 (1).jpg','assets/img/Lower Primary-jun-23 (2).jpeg','assets/img/Lower Primary-jun-23 (2).jpg','assets/img/Lower Primary-jun-23 (3).jpeg','assets/img/Lower Primary-jun-23 (3).jpg'],
        Date: '24-Jun-2023',
        newsid:'newsid03'
       }
     
           ];
}
}
